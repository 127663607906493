import { EMPTY_OBJECT } from './constants';
import { getCurrGroupMetaStatusChangesForNotification, verifyOrCreateKey } from './util';

export const _createSelectors = ({ selectThisSlice, selectParentSlice }) => { 

  const selectPrevSaveGroupMetaMap = (state) => {
    const { prevSaveGroupMeta } = selectThisSlice(state);
    return prevSaveGroupMeta;
  };

  const selectPrevSaveGroupMeta = (state, { key, projectId, lineGuid, surveyGuid } = {}) => {
    const _key = verifyOrCreateKey(key, projectId, lineGuid, surveyGuid);
    const map = selectPrevSaveGroupMetaMap(state);
    return map[_key] || EMPTY_OBJECT;
  };

  const selectCurrGroupMetaMap = (state) => {
    const { currGroupMeta } = selectThisSlice(state);
    return currGroupMeta;
  };

  const selectCurrGroupMeta = (state, { key, projectId, lineGuid, surveyGuid } = {}) => {
    const _key = verifyOrCreateKey(key, projectId, lineGuid, surveyGuid);
    const map = selectCurrGroupMetaMap(state);
    return map[_key] || EMPTY_OBJECT;
  };

  const selectCurrGroupMetaStatusChangesMap = (state) => {
    const { currGroupMetaStatusChanges } = selectThisSlice(state);
    return currGroupMetaStatusChanges;
  };

  const selectCurrGroupMetaStatusChanges = (state, { key, projectId, lineGuid, surveyGuid } = {}) => {
    const _key = verifyOrCreateKey(key, projectId, lineGuid, surveyGuid);
    const map = selectCurrGroupMetaStatusChangesMap(state);
    return map[_key] || EMPTY_OBJECT;
  };

  const selectCurrReportStateMap = (state) => {
    const { currReportState } = selectThisSlice(state);
    return currReportState;
  };

  const selectCurrReportState = (state, { key, projectId, lineGuid, surveyGuid } = {}) => {
    const _key = verifyOrCreateKey(key, projectId, lineGuid, surveyGuid);
    const map = selectCurrReportStateMap(state);
    return map[_key] || EMPTY_OBJECT;
  };

  const selectCurrGroupMetaStatusChangesForNotification = (state, { key, projectId, lineGuid, surveyGuid } = {}) => { 
    const currGroupMetaStatusChanges = selectCurrGroupMetaStatusChanges(state, { key, projectId, lineGuid, surveyGuid });
    return getCurrGroupMetaStatusChangesForNotification(currGroupMetaStatusChanges, { includeToStatuses: ['Complete'] });
  }

  return {
    selectThisSlice,
    selectParentSlice,

    selectPrevSaveGroupMetaMap,
    selectPrevSaveGroupMeta,
    selectCurrGroupMetaMap,
    selectCurrGroupMeta,
    selectCurrGroupMetaStatusChangesMap,
    selectCurrGroupMetaStatusChanges,
    selectCurrReportStateMap,
    selectCurrReportState,
    selectCurrGroupMetaStatusChangesForNotification
  }
}

export default _createSelectors;
