/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */


const fallback = (obj1, obj2) => {
  if (!obj1 || !Object.keys(obj1).length) {
    return obj2;
  }
  return obj1;
}


export const _createReducerParts = ({ sliceConfig, ACTIONS = {}, SELECTORS = {} }) => {
  const { prop: sliceProp } = sliceConfig;
  
  // ************
  // PARTS
  // ************
  return {
    [ACTIONS.setPrevSaveGroupMeta]: (state, { payload: { groupMeta, key } }) => {
      const slice = SELECTORS.selectThisSlice(state);
      const map = fallback(SELECTORS.selectPrevSaveGroupMetaMap(state), {});
  
      return {
        ...state,
        [sliceProp]: {
          ...slice,
          prevSaveGroupMeta: {
            ...map,
            [key]: groupMeta
          }
        }
      };
    },
    
    [ACTIONS.setCurrGroupMeta]: (state, { payload: { groupMeta, key } }) => {
      const slice = SELECTORS.selectThisSlice(state);
      const map = fallback(SELECTORS.selectCurrGroupMetaMap(state), {});
  
      return {
        ...state,
        [sliceProp]: {
          ...slice,
          currGroupMeta: {
            ...map,
            [key]: groupMeta
          }
        }
      };
    },
    
    [ACTIONS.setCurrGroupMetaStatusChanges]: (state, { payload: { statusChanges, key } }) => {
      const slice = SELECTORS.selectThisSlice(state);
      const map = fallback(SELECTORS.selectCurrGroupMetaStatusChangesMap(state), {});
  
      return {
        ...state,
        [sliceProp]: {
          ...slice,
          currGroupMetaStatusChanges: {
            ...map,
            [key]: statusChanges
          }
        }
      };
    },
    
    [ACTIONS.setCurrReportState]: (state, { payload: { currReport, key } }) => {
      const slice = SELECTORS.selectThisSlice(state);
      const map = fallback(SELECTORS.selectCurrReportStateMap(state), {});
  
      return {
        ...state,
        [sliceProp]: {
          ...slice,
          currReportState: {
            ...map,
            [key]: currReport
          }
        }
      };
    },

  };
}


export default _createReducerParts;
