/* eslint-disable no-confusing-arrow */

import { createSortNumeric } from "aegion_common_utilities/lib/Sort";
import { parseQueryString } from "../../../../utils/location";


/**
 * BASE HELPERS
 */

export const isDefined = v => v !== null && v !== undefined;

export const isEmptyString = v =>
	typeof v === 'string' && v.trim().length === 0;

export const isEmptyObject = o =>
	typeof o === 'object' && Object.keys(o).length === 0;


/**
 * SORTS
 */

const _getDateCreatedEpoch = (action) => {
  const date = action?.dateCreated || undefined;
  if (!date) {
      return -1;
  }
  const epoch = new Date(date).getTime();
  return Number.isNaN(epoch) ? -1 : epoch;
};

const sortActionsByDateCreated = createSortNumeric('ASC', { 
  getValue: (action) => (_getDateCreatedEpoch(action))
});

const sortGroupKeys = createSortNumeric('ASC', { getValue: (i) => ((i || '').split('-')[0]), invalidLast: true });



/**
 * KEYS
 */

export const createKey = (projectId, lineGuid, surveyGuid) => {
	return [projectId, lineGuid, surveyGuid].filter(v => v).join('~');
};

export const getKey = createKey;

export const verifyOrCreateKey = (key, projectId, lineGuid, surveyGuid) => {
  if (key) {
    return key;
  }

  if (!projectId && !lineGuid) {
    throw new Error('function verifyOrCreateKey: neither projectId nor lineGuid is defined. Either pass in an existing key or the props required to create a key.')
  }

  if (!surveyGuid) {
    throw new Error('function verifyOrCreateKey: surveyGuid is not defined. Either pass in an existing key or the props required to create a key.')
  }

  return createKey(projectId, lineGuid, surveyGuid);
};

/**
 * COMPUTATION HELPERS
 */

const _getGroupKeys = (report) => {
  const groups = report?.meta?.remediationGroups;
  const keys = groups.map(g => [g[0]?.id, g[1]?.id].filter(v => isDefined(v)).join('-'));
  return keys;
}

const _getCleanGroupMeta = (report) => {
  const keys = _getGroupKeys(report);
  const fallbackGroupMeta = { status: 'New' };
  const groupMetaMap = report?.meta?.groupMeta;
  return keys.reduce((acc, key) => {
    const group = groupMetaMap[key] || { ...fallbackGroupMeta };
    acc[key] = group;
    return acc;
  }, {});
}

const _getEnrichedCleanGroupMeta = (report, surveyId) => { 
  const cleanGroupMeta = _getCleanGroupMeta(report);
  const actionsMap = report?.meta?.remediationActions || {};
  const surveyKey = surveyId || Object.keys((actionsMap))[0];
  const actions = actionsMap[surveyKey];
  const groupKeys = Object.keys(cleanGroupMeta);
  return groupKeys.reduce((acc, groupKey) => { 
    const groupActions = actions[groupKey];
    const actionKeys = Object.keys(groupActions || {});
    const actionsList = actionKeys.map((actionKey) => groupActions[actionKey])
    actionsList.sort(sortActionsByDateCreated);
    const lastAction = (actionsList || []).at(-1);
    acc[groupKey] = {
      ...cleanGroupMeta[groupKey],
      lastAction
    };
    return acc;
  }, {})
}

/**
 * COMPUTATIONS
 */

export const computeRobustGroupMeta = (report, surveyId) => {
  return _getEnrichedCleanGroupMeta(report, surveyId);
}

export const computeGroupMetaStatusChanges = (
  prevSaveGroupMeta,
  currGroupMeta,
  {
    report,
    lineOrProjectName,
    projectId,
    lineGuid,
    surveyGuid,
    pipe // pipeId ie pipeName
  } = {}) => {

    const {
      linename: urlLineName,
      project: urlProjectName,
      line: urlLineGuid,
      p_id: urlProjectId
    } = parseQueryString(window?.location?.search);
  
    const _name = lineOrProjectName || urlLineName || urlProjectName;
    const _projectId = projectId || urlProjectId;
    const _lineGuid = urlLineGuid || lineGuid; 
    const _surveyGuid = surveyGuid || report?.meta?.primarySurveyGuid;
    
    const prevGroupKeys = Object.keys(prevSaveGroupMeta);
    const currGroupKeys = Object.keys(currGroupMeta);
    const groupKeys = [...new Set([...prevGroupKeys, ...currGroupKeys])].sort(sortGroupKeys);
  
    const collection = {
      projectId: _projectId,
      lineId: _lineGuid,
      name: _name,
      pipe,
      surveyId: _surveyGuid,
      validGroupKeys: groupKeys,
      statusChanges: []
    };

  groupKeys.forEach((groupKey) => { 
    const prev = prevSaveGroupMeta[groupKey];
    const curr = currGroupMeta[groupKey];

    if (!prev) {
      collection.statusChanges.push({
        groupKey,
        prevStatus: '',
        toStatus: curr?.status,
        updatedBy: curr?.updatedBy,
        dateUpdated: curr?.dateUpdated,
        lastAction: curr?.lastAction,
      });
    } else if (!curr) {
      collection.statusChanges.push({
        groupKey,
        prevStatus: prev?.status,
        toStatus: 'DELETED',
        updatedBy: prev?.updatedBy,
        dateUpdated: prev?.dateUpdated,
        lastAction: prev?.lastAction,
      });
    } else if (prev?.status !== curr?.status) {
      collection.statusChanges.push({
        groupKey,
        prevStatus: prev?.status || 'New',
        toStatus: curr?.status,
        updatedBy: curr?.updatedBy,
        dateUpdated: curr?.dateUpdated,
        lastAction: curr?.lastAction,
      });
    }
  });
  
  return collection;
}

export const getCurrGroupMetaStatusChangesForNotification = (
  currGroupMetaStatusChanges,
  { includeToStatuses = [] } = {}
) => { 
  if (!includeToStatuses?.length) {
    return currGroupMetaStatusChanges;
  }
  return {
    ...currGroupMetaStatusChanges,
    statusChanges: (currGroupMetaStatusChanges?.statusChanges || []).filter((statusChange) => { 
      return includeToStatuses.indexOf(statusChange?.toStatus) > -1;
    })
  };
}

export default {
  // BASE HELPERS
	isDefined,
	isEmptyString,
	isEmptyObject,
  // KEYS
	createKey,
	getKey,
	verifyOrCreateKey,
  // COMPUTATIONS
  computeRobustGroupMeta,
  computeGroupMetaStatusChanges,
  getCurrGroupMetaStatusChangesForNotification,
};
