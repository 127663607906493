import SLICE from './_create.slice';

const { ACTIONS } = SLICE;

export const {
  setPrevSaveGroupMeta,
  setCurrGroupMeta,
  setCurrReportState,
  setCurrGroupMetaStatusChanges,
  thunkSetPrevSaveGroupMeta,
  thunkSetCurrReportState,
  thunkOnSaveComplete
} = ACTIONS;