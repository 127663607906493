/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import scanlineUtils from '../../../../scanlineUtils';
import { thunkLoadActionPlanReadings } from '../ActionPlanReadings/actions';
import { setIsSyncEnabled } from '../ActionPlanSync/actions';
import { selectActionPlanStateSlice, selectIsSyncEnabled } from './selectors';
import { thunkSetPrevSaveGroupMeta } from '../../../AppAnalysisPage/slices/ActionPlanGroupStatusChanges/actions';
import { parseQueryString } from '../../../../utils/location';

const isDefined = v => v !== undefined && v !== null;

const {
	requestActionPlanState,
	receiveActionPlanState,
	setActionReportThreshold,
	setActionReportUseShadow,
	addActionReportCustomGroup,
	removeActionReportCustomGroup,
	clearActionReportCustomGroup,
	setActionReportVersion,
	setActionReportComparator
} = createActions(
	{
		REQUEST_ACTION_PLAN_STATE: () => ({}),
		RECEIVE_ACTION_PLAN_STATE: actionPlanState => ({
			actionPlanState
		}),
		SET_ACTION_REPORT_THRESHOLD: threshold => ({ threshold }),
		SET_ACTION_REPORT_USE_SHADOW: useShadow => ({ useShadow }),
		ADD_ACTION_REPORT_CUSTOM_GROUP: group => ({ group }),
		REMOVE_ACTION_REPORT_CUSTOM_GROUP: group => ({ group }),
		CLEAR_ACTION_REPORT_CUSTOM_GROUP: () => ({}),
		SET_ACTION_REPORT_VERSION: version => ({ version }),
		SET_ACTION_REPORT_COMPARATOR: comparator => ({ comparator })
	},
	{ prefix: 'ActionPlanStateV2' }
);

const thunkInitProjectSync = () => (dispatch, getState) => {
	const { app } = getState();
	const isSyncEnabled = selectIsSyncEnabled(app);
	if (isDefined(isSyncEnabled)) {
		dispatch(setIsSyncEnabled(isSyncEnabled));
	}
};

const thunkLoadActionPlanStateV2 = (
	jobId,
	lineId,
	projectid,
	callback = () => {}
) => {
	return (dispatch, getState) => {
		dispatch(requestActionPlanState());

		return scanlineUtils.getReportDataV2(
			jobId,
			lineId,
			projectid,
			'ACTION_PLAN',
			(err, res) => {
				if (err) {
					callback(err);
				} else {
					dispatch(receiveActionPlanState(res.content));
					dispatch(thunkInitProjectSync());
          dispatch(thunkLoadActionPlanReadings());          
          callback(null, res);
          // HANDLE GROUP STATUS CHANGE COMPUTATIONS
          setTimeout(() => { 
            const report = selectActionPlanStateSlice(getState())
            const {
              line: urlLineGuid,
              p_id: urlProjectId,
            } = parseQueryString(window?.location?.search);

            const {
              primarySurveyGuid: reportSurveyGuid
            } = report?.meta || {};

            const projectId = urlProjectId;
            const lineGuid = urlLineGuid;
            const surveyGuid = reportSurveyGuid;

            dispatch(thunkSetPrevSaveGroupMeta(report, { projectId, lineGuid, surveyGuid }));
          }, 1);
				}
			}
		);
	};
};
export {
	thunkLoadActionPlanStateV2 as loadActionPlanStateV2,
	receiveActionPlanState,
	requestActionPlanState,
	setActionReportThreshold,
	setActionReportUseShadow,
	addActionReportCustomGroup,
	removeActionReportCustomGroup,
	clearActionReportCustomGroup,
	setActionReportVersion,
	setActionReportComparator
};
