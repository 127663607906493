/* eslint-disable import/no-cycle */
import { createActions as createReduxActions } from 'redux-actions';
import { verifyOrCreateKey, computeRobustGroupMeta, computeGroupMetaStatusChanges } from './util';

export const _createActions = ({ sliceConfig, SELECTORS = {} }) => {
  const { prefix } = sliceConfig;
  const { setPrevSaveGroupMeta, setCurrGroupMeta, setCurrGroupMetaStatusChanges, setCurrReportState } = createReduxActions(
    {
      SET_PREV_SAVE_GROUP_META: (groupMeta, { key, projectId, lineGuid, surveyGuid}) => {
        const reportKey = verifyOrCreateKey(key, projectId, lineGuid, surveyGuid)
        return { groupMeta, key: reportKey };
      },
      SET_CURR_GROUP_META: (groupMeta, { key, projectId, lineGuid, surveyGuid}) => {
        const reportKey = verifyOrCreateKey(key, projectId, lineGuid, surveyGuid)
        return { groupMeta, key: reportKey };
      },
      SET_CURR_GROUP_META_STATUS_CHANGES: (statusChanges, { key, projectId, lineGuid, surveyGuid }) => {
        const reportKey = verifyOrCreateKey(key, projectId, lineGuid, surveyGuid)
        return { statusChanges, key: reportKey };
      },
      SET_CURR_REPORT_STATE: (currReport, { key, projectId, lineGuid, surveyGuid }) => {
        const reportKey = verifyOrCreateKey(key, projectId, lineGuid, surveyGuid)
        return { currReport, key: reportKey };
      },
    },
    {
      prefix
    }
  );

  const _thunkComputeCurrGroupMetaStatusChanges = (report, { key, projectId, lineGuid, surveyGuid }) => (dispatch, getState) => {
    const identifiers = { key, projectId, lineGuid, surveyGuid };
    const prevSaveGroupMeta = SELECTORS.selectPrevSaveGroupMeta(getState(), identifiers);
    const currGroupMeta = SELECTORS.selectCurrGroupMeta(getState(), identifiers);
    const groupMetaStatusChanges = computeGroupMetaStatusChanges(prevSaveGroupMeta, currGroupMeta, { report, ...identifiers });
    dispatch(setCurrGroupMetaStatusChanges(groupMetaStatusChanges, identifiers));
  }
  
  const thunkSetPrevSaveGroupMeta = (prevReport, { key, projectId, lineGuid, surveyGuid }) => (dispatch, getState) => {
    const reportKey = verifyOrCreateKey(key, projectId, lineGuid, surveyGuid);
    const identifiers = { key: reportKey, projectId, lineGuid, surveyGuid}
    const groupMeta = computeRobustGroupMeta(prevReport);
    const report = SELECTORS.selectCurrReportState(getState(), identifiers);
    dispatch(setPrevSaveGroupMeta(groupMeta, { key: reportKey }));
    dispatch(_thunkComputeCurrGroupMetaStatusChanges(report, identifiers))
  };
  
  const _thunkSetCurrGroupMeta = (currReport, { key, projectId, lineGuid, surveyGuid }) => (dispatch) => {
    const reportKey = verifyOrCreateKey(key, projectId, lineGuid, surveyGuid);
    const identifiers = { key: reportKey, projectId, lineGuid, surveyGuid}
    const groupMeta = computeRobustGroupMeta(currReport);
    dispatch(setCurrGroupMeta(groupMeta, identifiers));
    dispatch(_thunkComputeCurrGroupMetaStatusChanges(currReport, identifiers))
  };
  
  const thunkSetCurrReportState = (currReport, { key, projectId, lineGuid, surveyGuid }) => (dispatch) => {
    const reportKey = verifyOrCreateKey(key, projectId, lineGuid, surveyGuid);
    const identifiers = { key: reportKey, projectId, lineGuid, surveyGuid}
    dispatch(setCurrReportState(currReport, identifiers));
    dispatch(_thunkSetCurrGroupMeta(currReport, identifiers))
  };

  const thunkOnSaveComplete = ({ key, projectId, lineGuid, surveyGuid }) => (dispatch, getState) => {
    const identifiers = { key, projectId, lineGuid, surveyGuid}
    const report = SELECTORS.selectCurrReportState(getState(), identifiers);
    dispatch(thunkSetPrevSaveGroupMeta(report, identifiers))
  }

  return {
    setPrevSaveGroupMeta,
    setCurrGroupMeta,
    setCurrReportState,
    setCurrGroupMetaStatusChanges,
    thunkSetPrevSaveGroupMeta,
    thunkSetCurrReportState,
    thunkOnSaveComplete
  }
}

export default _createActions;
