/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// MAIN
import mainReducerParts from './main.reducer';
import { initialState as mainInitialState } from './main.initialState';

// ChartsSettings
import {
	reducerParts as chartsSettingsReducerParts,
	initialState as chartsSettingsInitialState
} from './slices/Charts';

// PageLoadState
import pageLoadStateReducerParts from '../_reusable/ScanAppAndReportStates/AppStateV2PageLoadState/reducerParts';
import { initialState as pageLoadStateInitialState } from '../_reusable/ScanAppAndReportStates/AppStateV2PageLoadState/initialState';

// AppStateV2
import appStateV2ReducerParts from '../_reusable/ScanAppAndReportStates/AppStateV2/reducerParts';
import { initialState as appStateV2InitialState } from '../_reusable/ScanAppAndReportStates/AppStateV2/initialState';

// ActionPlanStateV2
import actionPlanStateV2ReducerParts from '../_reusable/ScanAppAndReportStates/ActionPlanStateV2/reducerParts';
import { initialState as actionPlanStateV2InitialState } from '../_reusable/ScanAppAndReportStates/ActionPlanStateV2/initialState';

// ActionPlanReadings
import { ROOT_SLICE as ROOT_SLICE_ACTION_PLAN_READINGS } from '../_reusable/ScanAppAndReportStates/ActionPlanReadings/constants';
import { initialState as ActionPlanReadingsInitialState } from '../_reusable/ScanAppAndReportStates/ActionPlanReadings/initialState';
import ActionPlanReadingsReducerParts from '../_reusable/ScanAppAndReportStates/ActionPlanReadings/reducerParts';

// ActionPlanSync
import { ROOT_SLICE as ROOT_SLICE_ACTION_PLAN_SYNC } from '../_reusable/ScanAppAndReportStates/ActionPlanSync/constants';
import { initialState as ActionPlanSycnInitialState } from '../_reusable/ScanAppAndReportStates/ActionPlanSync/initialState';
import ActionPlanSyncReducerParts from '../_reusable/ScanAppAndReportStates/ActionPlanSync/reducerParts';

// ActionPlanMessages
import { ROOT_SLICE as ROOT_SLICE_ACTION_PLAN_MESSAGES } from '../_reusable/ScanAppAndReportStates/ActionPlanMessages/constants';
import { initialState as ActionPlanMessagesInitialState } from '../_reusable/ScanAppAndReportStates/ActionPlanMessages/initialState';
import ActionPlanMessagesReducerParts from '../_reusable/ScanAppAndReportStates/ActionPlanMessages/reducerParts';

// StoreXYMinMax
import { ROOT_SLICE as ROOT_SLICE_STORE_YMIN_YMAX } from './slices/StoreXYMinMax/constants';
import { initialState as StoreXYMinMaxInitialState } from './slices/StoreXYMinMax/initialState';
import StoreXYMinMaxReducerParts from './slices/StoreXYMinMax/reducerParts';

// ReadingsStore
import { ROOT_SLICE as ROOT_SLICE_READINGS_STORE } from './slices/ReadingsStore/constants';
import { initialState as ReadingsStoreInitialState } from './slices/ReadingsStore/initialState';
import ReadingsStoreReducerParts from './slices/ReadingsStore/reducerParts';

// ReportGroupStatusChange
import { ROOT_SLICE as ROOT_SLICE_REPORT_GROUP_STATUS_CHANGE } from './slices/ActionPlanGroupStatusChanges/constants';
import { initialState as ReportGroupStatusChangeInitialState } from './slices/ActionPlanGroupStatusChanges/initialState';
import ReportGroupStatusChangeReducerParts from './slices/ActionPlanGroupStatusChanges/reducerParts';

// Reset State
import { resetScanlineState } from '../../actions/app';
import { goodEnoughUUID } from '../../utils/uuid';

const initialState = {
	...chartsSettingsInitialState,
	...pageLoadStateInitialState,
	...appStateV2InitialState,
	...actionPlanStateV2InitialState,
	...mainInitialState,
	[ROOT_SLICE_ACTION_PLAN_READINGS]: {
		...ActionPlanReadingsInitialState
	},
	[ROOT_SLICE_ACTION_PLAN_SYNC]: {
		...ActionPlanSycnInitialState
	},
	[ROOT_SLICE_ACTION_PLAN_MESSAGES]: {
		...ActionPlanMessagesInitialState
	},
	[ROOT_SLICE_STORE_YMIN_YMAX]: {
		...StoreXYMinMaxInitialState
	},
	[ROOT_SLICE_READINGS_STORE]: {
		...ReadingsStoreInitialState
  },
  [ROOT_SLICE_REPORT_GROUP_STATUS_CHANGE]: {
    ...ReportGroupStatusChangeInitialState
  }
};

const reducerParts = {
	...chartsSettingsReducerParts,
	...pageLoadStateReducerParts,
	...appStateV2ReducerParts,
	...actionPlanStateV2ReducerParts,
	...mainReducerParts,
	...ActionPlanReadingsReducerParts,
	...ActionPlanSyncReducerParts,
	...ActionPlanMessagesReducerParts,
	...StoreXYMinMaxReducerParts,
  ...ReadingsStoreReducerParts,
  ...ReportGroupStatusChangeReducerParts,

	[resetScanlineState]: () => {
		return { ...initialState, stateResetUuid: goodEnoughUUID() };
	}
};

export default handleActions(reducerParts, initialState);
